import Loading from "../Loading";
import ScreenTemplate from "../Screen/ScreenTemplate";
import Header from "../Header/Header";
import style from "./Activity.module.css"
import homeStyle from "../Home.module.css";
import Footer from "../Footer/Footer";
import React from "react";
import Finish from "../Screen/Finish";
import Picture from "../Screen/Picture";
import ConnectComponent from "../../Services/ConnectionService";

export default class Activity extends ConnectComponent {

    constructor(props) {
        super(props);
        this.state = {
            openModal: false
        };
        this._stepRef = {};
        this._stepDivRef = {};
        this._steps = undefined;
        this._activity = undefined;
        this.isFirst = true;
        this.isFirstS = true;
        this.isFirstA = true;
        this.isLoading = true;
        this.isLoadingA = true;
        this._nbSteps = undefined;
        this._stepNumber = 1;
        this.header = null;
        this.footer = null;
        this.displayedSteps = [];
        this.stepWidth = 0;
        this.oKtoChange = true;
        this.newBadgesNb = 0;
        this.newBadges = [{}];
        this.finished = false;
        this.finishXp = 0;
        this.photoXp = 0;
        this.navigation = true;
        this._note = 0;
        this.auhId = 0;
    }

    doesMustLog() {
        this.mustLog = 1
    }

    getUserName() {
        if(this.state.user.id === this.props.user){
            return this.state.user.firstName
        }

        for(let i = 0; i < this.state.children.length; i++){
            if(this.state.children[i].user.id === this.props.user){
                return this.state.children[i].user.firstName
            }
        }

        return ""
    }

    stopLoading() {
        if(!this.isLoading && !this.isLoadingA) {
            this.loadingRef.animate([
                {opacity: '100%'},
                {opacity: '0%'},
            ], {
                duration: 500,
                easing: 'ease-out'
            })
            setTimeout(() => {
                this.forceUpdate()
            }, 500)
            setTimeout(() => {
                this._stepRef[this._stepNumber - 1].calculateShowNext()
            }, 1000)
        }
    }

    _loadSteps() {
        if(this.isFirstS) {
            this.isFirstS = false
            let request = new Request (process.env.REACT_APP_API_URL + "/activity/" + this.props.act + "/steps/" + this.props.user, {method:'POST', credentials:'include'});
            this.fetchJsonOrError(request,
                (data) => {
                    this._steps = data;
                    this._nbSteps = data.length;
                    this.isLoading = false
                    this._startStep(this._stepNumber).then(()=>{
                        this.stopLoading()
                    })
                }
            )
        }
    }

    _setPartXpNb() {
        for(const nbstep in this._steps) {
            if(this._steps[nbstep]["screen"][1]["type"] === "partxp"){
                if(!Object.keys(this.header.starSteps).includes((nbstep + 1).toString())){
                    this.header.setStepIsStar(parseInt(nbstep))
                }
            }
        }
        this.forceUpdate();
    }

    _loadActivity() {
        if(this.isFirstA) {
            this.isFirstA = false
            let request = new Request (process.env.REACT_APP_API_URL + "/activity/" + this.props.act + "/" + this.props.user, {method:'POST', credentials:'include'});
            this.fetchJsonOrError(request,
                (data) => {
                    this.isLoadingA = false;
                    this._activity = data;
                    document.title = data.name + " - App Web Atorika";
                    this.stopLoading()
                }
            )
        }
    }

    async _startStep(currStep) {
        if(this._steps === undefined || this._steps[currStep - 1] === undefined){
            return
        }
        let jsonD = new FormData()
        jsonD.append("json", JSON.stringify({"web": true}))
        let request = new Request(process.env.REACT_APP_API_URL + '/history/step/start/' + this._steps[currStep - 1].id
            + '/user/' + this.props.user, {method:'POST', body:jsonD, credentials:'include'})
        this.fetchJsonOrError(request, null, null, (resp)=>{})
    }

    async _endStep(currStep) {
        if(this._steps === undefined || this._steps[currStep - 1] === undefined){
            return
        }
        let jsonD = new FormData()
        jsonD.append("json", JSON.stringify({"success":this._steps[currStep - 1].success, "web": true}))
        let request = new Request(process.env.REACT_APP_API_URL + '/history/step/end/' + this._steps[currStep - 1].id
            + '/user/' + this.props.user, {method:'POST', body:jsonD, credentials:'include'})
        this.fetchJsonOrError(request, null, null, (resp)=>{})
    }

    setSuccess(step, bool) {
        this._steps[step - 1].success = bool
    }

    async _endActivity() {
        if (!this.finished) {
            let request = new Request(process.env.REACT_APP_API_URL + '/history/end/' + this.props.act
                + '/user/' + this.props.user, {method:'POST', credentials: 'include'})
            this.fetchJsonOrError(request, data => {
                    this.finished = true;
                    this.newBadgesNb = data['nbBadges'];
                    this.newBadges = data['badges'];
                    this.finishXp = data['xpDiff'];
                    this.photoXp = data['xpPhoto'];
                    this.auhId = data['auhId'];
                    this.forceUpdate()
                })
        }
    }

    _noteActivity () {
        let jsonD = new FormData()
        jsonD.append("json", JSON.stringify({"note":this._note}))
        let request = new Request(process.env.REACT_APP_API_URL + '/history/note/' + this.props.act
            + '/user/' + this.props.user, {method:'POST', body: jsonD, credentials: 'include'})
        this.fetchJsonOrError(request, null, null, (resp)=>{})
    }

    displaySteps() {
        if(this.isFirst && this.stepWidth) {
            this.isFirst = false
            let returnValue = [];
            for(const nbStep in this._steps) {
                let step = this._steps[nbStep];
                let translateX = 'translateX(' + (parseInt(nbStep) === 0 ? 0 : this.stepWidth).toString() + 'px)'
                returnValue.push(
                    <div
                        ref={(node) => { this._stepDivRef[nbStep] = node }}
                        style={{
                            zIndex: nbStep * 100,
                            position: "absolute",
                            left: 0, top: 0,
                            transform: translateX,
                            width: this.stepWidth,
                            backgroundColor: "#f9fbfd",
                            height: "100%",
                            display: "flex",
                            borderTopLeftRadius: "40px",
                            borderTopRightRadius: "40px",
                    }}
                    >
                        <ScreenTemplate
                            ref={(node) => {this._stepRef[nbStep] = node}}
                            step={step}
                            nbstep={nbStep}
                            stepFocus={this._stepNumber}
                            setShowNext={this.setShowNext.bind(this)}
                            setSuccess={this.setSuccess.bind(this)}
                            actionNext={this.actionNext.bind(this)}
                            addXp={this.footer.addXp.bind(this.footer)}
                            expandStar={this.footer.expandStar.bind(this.footer)}
                            addTimer={this.addTimer.bind(this)}
                            setRemainingTime={this.setRemainingTime.bind(this)}
                        />
                    </div>
                )
            }
            this.displayedSteps = returnValue
        }
        return this.displayedSteps
    }

    displayStep(step, duration = 300) {
        let translateX = 'translateX(' + this.stepWidth.toString() + 'px)'
        this._stepDivRef[step-1].animate([
            {transform: translateX},
            {transform: 'translateX(0)'},
        ], {
            duration: duration,
            easing: 'ease-out'
        })
        this._stepDivRef[step-1].style = "z-index: "+((step-1) * 100).toString() + "; position: absolute; left: 0; top: 0; width: " + this.stepWidth.toString() + "px; background-color: #f9fbfd; height: 100%; display: flex; border-top-left-radius: 40px; border-top-right-radius: 40px;";
    }

    unDisplayStep(step, duration = 300) {
        let translateX = 'translateX(' + this.stepWidth.toString() + 'px)'
        this._stepDivRef[step-1].animate([
            {transform: 'translateX(0)'},
            {transform: translateX},
        ], {
            duration: duration,
            easing: 'ease-out'
        })
        this._stepDivRef[step-1].style = "z-index: "+((step-1) * 100).toString() + "; position: absolute; left: 0; top: 0; transform:" + translateX + "; width: " + this.stepWidth + "px; background-color: #f9fbfd; height: 100%; display: flex; border-top-left-radius: 40px; border-top-right-radius: 40px;";
    }

    getWidth = (element) => {
        if(element && !this.stepWidth) {
            this.isFirst = true
            this.stepWidth = element.clientWidth
            this.forceUpdate()
            this._setPartXpNb()
        }
    }

    changeStepNumber(newStep) {
        if(this.oKtoChange) {
            this.oKtoChange = false
            if(newStep - this._stepNumber === 1) {
                this._endStep(this._stepNumber).then(() => {
                    this._stepRef[this._stepNumber-1].changeStepFocus(newStep)
                    this._stepNumber = newStep;
                    this._startStep(this._stepNumber).then(() => {
                        this.displayStep(this._stepNumber);
                        this._stepRef[this._stepNumber-1].changeStepFocus(newStep)
                        this._stepRef[this._stepNumber-1].calculateShowNext()
                        setTimeout(() => {this.oKtoChange = true}, 300)
                        this.header.setLastDone(this._stepNumber - 1)
                        this.header.setStepNumber(this._stepNumber - 1)
                        this.header.setStarIsDone(this._stepNumber - 1)
                    })
                })
            }
            else if(newStep - this._stepNumber === -1) {
                this._endStep(this._stepNumber).then(() => {
                    this._stepRef[this._stepNumber-1].changeStepFocus(newStep)
                    this._stepNumber = newStep;
                    this._startStep(this._stepNumber).then(() => {
                        this._stepRef[this._stepNumber-1].changeStepFocus(newStep)
                        this._stepRef[this._stepNumber-1].calculateShowNext()
                        this.unDisplayStep(this._stepNumber + 1);
                        this.header.setStepNumber(this._stepNumber - 1)
                        setTimeout(() => {this.oKtoChange = true}, 300)
                    })
                })
            }
            else if (newStep - this._stepNumber > 0) {
                this._endStep(this._stepNumber).then(() => {
                    this._startStep(newStep).then(() => {
                        this._stepRef[this._stepNumber-1].changeStepFocus(newStep)
                        for(let i = this._stepNumber + 1; i <= newStep; i++) {
                            setTimeout(() => {this.displayStep(i, 200)}, (i-this._stepNumber-1)*120)
                        }
                        this._stepNumber = newStep;
                        this._stepRef[this._stepNumber-1].changeStepFocus(newStep)
                        this.header.setStepNumber(this._stepNumber - 1)
                        setTimeout(() => {this.oKtoChange = true; this._stepRef[this._stepNumber-1].calculateShowNext()}, 500)
                    })
                })
            }
            else if (newStep - this._stepNumber < 0) {
                this._endStep(this._stepNumber).then(() => {
                    this._startStep(newStep).then(() => {
                        this._stepRef[this._stepNumber-1].changeStepFocus(newStep)
                        for(let i = this._stepNumber; i > newStep; i--) {
                            setTimeout(() => {this.unDisplayStep(i, 200)}, (this._stepNumber-i)*120)
                        }
                        this._stepNumber = newStep;
                        this._stepRef[this._stepNumber-1].changeStepFocus(newStep)
                        this.header.setStepNumber(this._stepNumber - 1)
                        setTimeout(() => {this.oKtoChange = true; this._stepRef[this._stepNumber-1].calculateShowNext()}, 500)
                    })
                })
            } else {
                this.oKtoChange = true
            }
        }
    }

    setShowNext(show) {
        this.footer.setShowNext(show)
    }

    setNote(note) {
        this._note = note
    }

    goHome() {
        this._noteActivity()
        setTimeout(this.closeTab.bind(this), 500)
    }

    openModal() {
        if(this._stepNumber === this._nbSteps + 2){
            this.closeTab()
        } else {
            this.setState({openModal: true})
        }
    }

    closeTab() {
        window.onbeforeunload = undefined;
        this.exitFullScreen()
        setTimeout(() => {window.close()}, 50);
    }

    goPhoto() {
        this._noteActivity()
        this.actionNext()
    }

    actionNext() {
        if(this._stepNumber < this._nbSteps) {
            this._stepRef[this._stepNumber - 1].nextTrigger().then((bool) => {
                if (bool) {
                    this.changeStepNumber(this._stepNumber + 1)
                }
            })
        } else if(this._stepNumber === this._nbSteps) {
            this._stepRef[this._stepNumber - 1].nextTrigger().then((bool) => {
                if (bool) {
                    this.navigation = false
                    this._endStep(this._stepNumber).then(() => {
                        this._stepRef[this._stepNumber-1].changeStepFocus(this._nbSteps + 1)
                        this.setShowNext(false)
                        this._stepNumber = this._nbSteps + 1;
                        this._endActivity().then(() => {
                            this.header.setLastDone(this._stepNumber + 1)
                            this.header.setStarIsDone(this._stepNumber - 1)
                            this.displayStep(this._stepNumber)
                            setTimeout(() => {this.oKtoChange = true}, 500)
                        })
                    })
                }
            })
        } else if(this._stepNumber === this._nbSteps + 1) {
            this._stepNumber = this._nbSteps + 2;
            this.displayStep(this._stepNumber)
        }
    }

    addTimer = (bool) => {
        this.footer.addTimer(bool)
    }

    setRemainingTime = (rT, dur) => {
        this.footer.setRemainingTime(rT, dur)
    }

    displayActivity() {
        if(this._activity !== undefined && this._steps !== undefined) {
            return (
                <div className={homeStyle.mainContainer}>

                    <div className={homeStyle.headerContainer}>
                        <Header
                            ref={(node) => { this.header = node }}
                            name={this._activity.name}
                            duration={this._activity.estimatedDuration}
                            ageMin={this._activity.ageMin}
                            theme={this._activity.them}
                            themeColor={this._activity.themColor}
                            themeBaseColor={this._activity.themBaseColor}
                            secondTheme={this._activity.secondThem}
                            secondThemeColor={this._activity.secondThemColor}
                            secondThemeBaseColor={this._activity.secondThemBaseColor}
                            nbSteps={this._nbSteps}
                            lastDone={0}
                            progressBar={true}
                            changeStepNumber={this.changeStepNumber.bind(this)}
                            navigation={this.navigation}
                            stepNumber={this._stepNumber - 1}
                        />
                    </div>

                    <div className={homeStyle.screenContainer} ref={this.getWidth.bind(this)}>
                        <div className={style.container} style={{position: "relative"}}>
                            {this.displaySteps()}
                            <div
                                ref={(node) => { this._stepDivRef[this._nbSteps] = node }}
                                style={{
                                    zIndex: this._nbSteps * 100,
                                    position: "absolute",
                                    left: 0, top: 0,
                                    transform: 'translateX(' + this.stepWidth.toString() + 'px)',
                                    width: this.stepWidth + "px",
                                    backgroundColor: "#f9fbfd",
                                    height: "100%",
                                    display: "flex",
                                    borderTopLeftRadius: "40px",
                                    borderTopRightRadius: "40px",
                                }}
                            >
                                <Finish
                                    newBadgesNb={this.newBadgesNb}
                                    newBadges={this.newBadges}
                                    xp={this.finishXp}
                                    isFocus={this._stepNumber === this._nbSteps + 1}
                                    xpPhoto={this.photoXp}
                                    setNote={this.setNote.bind(this)}
                                    goHome={this.goHome.bind(this)}
                                    goPhoto={this.goPhoto.bind(this)}
                                    mascotte={this._activity.mascotte.finish}
                                    addXp={this.footer ? this.footer.addXp.bind(this.footer) : () => {}}
                                    expandStar={this.footer ? this.footer.expandStar.bind(this.footer) : () => {}}
                                    dialogue={this._activity.mascotte.finish.dialogue.replace("_name_", this.getUserName())}
                                />
                            </div>
                            <div
                                ref={(node) => { this._stepDivRef[this._nbSteps + 1] = node }}
                                style={{
                                    zIndex: (this._nbSteps + 1) * 100,
                                    position: "absolute",
                                    left: 0, top: 0,
                                    transform: 'translateX(' + this.stepWidth.toString() + 'px)',
                                    width: this.stepWidth,
                                    backgroundColor: "#f9fbfd",
                                    height: "100%",
                                    display: "flex",
                                    borderTopLeftRadius: "40px",
                                    borderTopRightRadius: "40px",
                                }}
                            >
                                <Picture
                                    isFocus={this._stepNumber === this._nbSteps + 2}
                                    xp={this.photoXp}
                                    qrCode={"ACTIVITY_USER_HISTORY_" + this.auhId.toString()}
                                    closeTab={this.closeTab.bind(this)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={homeStyle.footerContainer} style={{zIndex: this._nbSteps * 110}}>
                        <Footer
                            ref={(node) => { this.footer = node }}
                            xp={this._activity.xpWon}
                            actionNext={this.actionNext.bind(this)}
                            showNext={false}
                            closeTab={this.openModal.bind(this)} //this.closeTab.bind(this)}
                            totalXp={this._activity.realXp}
                        />
                    </div>

                    {this.state.openModal &&
                        <div
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.8)",
                                zIndex: 100000000,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <div
                                style={{
                                    maxHeight: window.innerHeight > 1500 ? "40%" : "50%",
                                    aspectRatio: 600/635,
                                    padding: window.innerWidth > 1300 ? "40px" : "20px",
                                    position: "relative"
                                }}
                                className={style.modal}
                            >
                                <span className={style.title} style={{color: '#CE3534'}}>
                                    Es-tu sûr de vouloir quitter l'activité ?
                                </span>

                                <p className={style.text}>
                                    Tu perdras ta progression.
                                </p>

                                <div style={{display: "flex", gap: "10px", flexDirection: "column"}}>
                                    <button
                                        className={style.closeBtn}
                                        onClick={() => this.setState({openModal: false})}
                                    >
                                        Continuer l'activité
                                    </button>
                                    <button
                                        className={style.leaveBtn}
                                        onClick={this.closeTab.bind(this)}
                                    >
                                        Quitter
                                    </button>
                                </div>

                            </div>
                        </div>
                    }
                </div>
            )
        }
    }

    displayLoading() {
        return (
            <div
                ref={(node) => {this.loadingRef = node}}
                style={{
                    position: "absolute",
                    left: 0, top: 0,
                    opacity: '100%',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white"
            }}
            >
                <Loading/>
            </div>
        )
    }

    renderChild() {
        window.onbeforeunload = (() => true);

        if(this.isLoading || this.isLoadingA) {
            this._loadSteps()
            this._loadActivity()
        }
        return (
            <div>
                {this.displayActivity()}
                {(this.isLoading || this.isLoadingA) && this.displayLoading()}
            </div>
        )
    }

}
