import React, {Component} from "react"
import Lottie from "lottie-react"

class LottieComponent extends Component {

    constructor(props) {
        super(props)
        this._lottie = undefined;
        this.width = 0;
        this.height = 0;
        this.state = {
            height: 0,
            width: 0
        }
    }

    componentDidMount() {
        const height = this.divElement.clientHeight;
        const width = this.divElement.clientWidth;
        this.setState({ height: height, width: width});
    }

    _loadLottie() {
        if(this._lottie === undefined){
            let request = new Request(process.env.REACT_APP_CDN_URL + "/uploads/steps/lotties/" + this.props.file, {method:'POST'});

            fetch(request).then((response) =>response.json())
                .then(data => {
                    this._lottie = data;
                    this.width = data.w;
                    this.height = data.h;
                    this.forceUpdate();
                })
        }
    }

    render() {
        this._loadLottie()

        return (
            <div
                style={{
                    flex: this.props.size,
                    maxHeight: this.props.size * 100/6 + "%",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
            }}
                ref={ (divElement) => { this.divElement = divElement } }
            >
                {this._lottie &&
                    <Lottie
                        animationData={this._lottie}
                        loop={!this.props.noloop}
                        style={{
                            height: this.props.forced ? this.state.width * this.height/this.width + "px" : "100%",
                            width: this.props.forced ? this.state.width + "px" : "100%",
                            display: "flex",
                            alignItems: "center"
                    }}/>
                }

                {this.props.broken &&
                    <img
                        src={require("../../../assets/LigneBrisee/" + this.props.ligneBrisee)}
                        style={{position: "absolute", bottom: 0, width: "101%"}}
                    />
                }

                {/*<Lottie*/}
                {/*    options={{*/}
                {/*        loop: false,*/}
                {/*        path: 'https://labs.nearpod.com/bodymovin/demo/pancakes/data.json',*/}
                {/*    }}*/}
                {/*    height={200}*/}
                {/*    width={300}*/}
                {/*/>*/}


            </div>
        )
    }
}

export default LottieComponent;